import React, { SVGProps } from 'react';

const SvgMailAt = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg viewBox='0 0 24 24' className='svg-icon' {...props}>
			<g fill='none' fillRule='evenodd'>
				<path d='M0 0h24v24H0z' />
				<path
					d='M11.575 21.2c-5.4 0-8.725-3.8-8.725-8.625 0-5.7 4.525-9.525 9.675-9.525 4.925 0 8.6 3.025 8.6 7.8 0 4.35-2.3 6.075-4.6 6.075-1.125 0-2.05-.525-2.45-1.275-.775.75-1.95 1.225-3.075 1.225-2.75 0-4.15-1.95-4.15-4.3 0-3.025 2.2-5.475 5.425-5.475.925 0 1.675.25 2.25.675l.1-.425H17l-1.175 5.5c-.225 1.1.025 1.975 1.1 1.975 1.325 0 2.1-1.1 2.1-4.025 0-3.9-3.075-5.725-6.525-5.725-3.875 0-7.45 2.675-7.45 7.5 0 3.95 2.525 6.525 6.525 6.525 1.5 0 3.05-.325 4.4-1.025L16.8 20.1c-1.55.7-3.6 1.1-5.225 1.1zm-.175-6.675c.65 0 1.3-.175 1.825-.7l.8-3.7c-.45-.475-1.1-.7-1.725-.7-1.65 0-2.85 1.275-2.85 2.95 0 1.3.625 2.15 1.95 2.15z'
					fill='currentColor'
				/>
			</g>
		</svg>
	);
};

export default SvgMailAt;
