import React, { SVGProps } from 'react';

const SvgArrows = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg viewBox='0 0 24 24' className='svg-icon' {...props}>
			<g fill='none' fillRule='evenodd'>
				<path d='M0 0h24v24H0z' />
				<path
					d='M11 11V3.757a1 1 0 112 0V11h8.757a1 1 0 010 2H13v8.757a1 1 0 01-2 0V13H3.757a1 1 0 110-2H11z'
					fill='currentColor'
					opacity={0.3}
				/>
				<path
					d='M3.515 12l2.121 2.121c.39.553.39 1.024 0 1.415-.39.39-.862.39-1.414 0L.686 12l3.536-3.536c.471-.47.943-.47 1.414 0 .471.472.471.943 0 1.415L3.515 12zM12 20.485l2.121-2.121c.553-.39 1.024-.39 1.415 0 .39.39.39.862 0 1.414L12 23.314l-3.536-3.536c-.47-.471-.47-.943 0-1.414.472-.471.943-.471 1.415 0L12 20.485zM20.485 12l-2.121-2.121c-.39-.553-.39-1.024 0-1.415.39-.39.862-.39 1.414 0L23.314 12l-3.536 3.536c-.471.47-.943.47-1.414 0-.471-.472-.471-.943 0-1.415L20.485 12zM12 3.515L9.879 5.636c-.553.39-1.024.39-1.415 0-.39-.39-.39-.862 0-1.414L12 .686l3.536 3.536c.47.471.47.943 0 1.414-.472.471-.943.471-1.415 0L12 3.515z'
					fill='currentColor'
				/>
			</g>
		</svg>
	);
};

export default SvgArrows;
