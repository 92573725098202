import React, { SVGProps } from 'react';

const SvgDayRain = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg viewBox='0 0 24 24' className='svg-icon' {...props}>
			<g fill='none' fillRule='evenodd'>
				<path d='M0 0h24v24H0z' />
				<path
					d='M6.5 24A1.5 1.5 0 015 22.5c0-.552.5-1.386 1.5-2.5 1 1.114 1.5 1.948 1.5 2.5A1.5 1.5 0 016.5 24zm5 0a1.5 1.5 0 01-1.5-1.5c0-.552.5-1.386 1.5-2.5 1 1.114 1.5 1.948 1.5 2.5a1.5 1.5 0 01-1.5 1.5zm5 0a1.5 1.5 0 01-1.5-1.5c0-.552.5-1.386 1.5-2.5 1 1.114 1.5 1.948 1.5 2.5a1.5 1.5 0 01-1.5 1.5zm-3-11a3.5 3.5 0 110-7 3.5 3.5 0 010 7zm6.242-4.125h1.312a1.312 1.312 0 110 2.625h-1.312a1.313 1.313 0 010-2.625zm-3.01-4.05l.929-.928a1.313 1.313 0 011.856 1.856l-.928.928a1.313 1.313 0 01-1.856-1.856zM13.18 1c.725 0 1.313.588 1.313 1.313v1.312a1.312 1.312 0 11-2.625 0V2.312c0-.724.588-1.312 1.312-1.312zM6.89 3.897a1.313 1.313 0 011.856 0l.928.928A1.312 1.312 0 117.817 6.68l-.928-.928a1.313 1.313 0 010-1.856z'
					fill='currentColor'
					opacity={0.3}
				/>
				<path
					d='M4.747 18.043a6 6 0 119.064-6.543h2.439a3.75 3.75 0 010 7.5h-9c-.962 0-1.84-.362-2.503-.957z'
					fill='currentColor'
				/>
			</g>
		</svg>
	);
};

export default SvgDayRain;
