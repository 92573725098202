import React, { SVGProps } from 'react';

const SvgChair1 = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg viewBox='0 0 24 24' className='svg-icon' {...props}>
			<g fill='none' fillRule='evenodd'>
				<path d='M0 0h24v24H0z' />
				<path
					d='M12 2a4.854 4.854 0 014.342 2.683L19 10a5.528 5.528 0 01-4.944 8H9.944A5.528 5.528 0 015 10l2.658-5.317A4.854 4.854 0 0112 2zM7.55 13.6c1.456 1.092 2.944 1.65 4.45 1.65 1.506 0 2.994-.558 4.45-1.65l-.9-1.2c-1.21.908-2.39 1.35-3.55 1.35-1.16 0-2.34-.442-3.55-1.35l-.9 1.2z'
					fill='currentColor'
				/>
				<path
					d='M6.16 21.06l2-3.464a1 1 0 111.732 1l-2 3.464a1 1 0 11-1.732-1zm11.672-.03a1 1 0 01-1.732 1l-2-3.464a1 1 0 111.732-1l2 3.464z'
					fill='currentColor'
					opacity={0.3}
				/>
			</g>
		</svg>
	);
};

export default SvgChair1;
