// import { useEffect, useState } from 'react';
// import usersDb, { TUser } from '../db/users.db';
// import axios from 'axios';
// import { Header, API_URL } from '../../api';

// const useFakeUserAPI = (username: string) => {
// 	const allUserData = usersDb;
// 	const userData = allUserData.filter((f) => f.username === username)[0];

// 	const getCheckUser = async (userNameOrMail: string, password: string) => {
// 		try {
// 			const response = await axios.post(
// 				API_URL+'/auth/login',
// 				{
// 					email: userNameOrMail,
// 					password: password,
// 				},
// 				Header,
// 			);

// 			const data = response.data;
// 			return data;
// 		} catch (error: any) {
// 			console.error('Error:', error.message);
// 			// Handle the error
// 		}
// 	};

// 	const [isLoading, setIsLoading] = useState<boolean>(true);
// 	const [response, setResponse] = useState<TUser | undefined>();
// 	useEffect(() => {
// 		setTimeout(() => {
// 			setResponse(userData);
// 			setIsLoading(false);
// 		}, 500);

// 		// eslint-disable-next-line react-hooks/exhaustive-deps
// 	}, [username]);

// 	return { response, isLoading, getCheckUser, allUserData };
// };

// export default useFakeUserAPI;



import { useEffect, useState } from 'react';
import usersDb, { TUser } from '../db/users.db';
import axios from 'axios';
import { Header, API_URL } from '../../api';

const useFakeUserAPI = (username: string) => {
  const allUserData = usersDb;
  const userData = allUserData.find((f) => f.username === username);

  const getCheckUser = async (userNameOrMail: string, password: string) => {
    try {
      const response = await axios.post(
        `${API_URL}/auth/login`,
        {
          email: userNameOrMail,
          password: password,
        },
        Header,
      );
      return response.data;
    } catch (error: any) {
      console.error('Error:', error.message);
      throw error;
    }
  };

  const signupUser = async (email: string, password: string) => {
    try {
      const response = await axios.post(
        `${API_URL}/auth/register`, 
        {
          email: email,
          password: password,
        },
        Header,
      );
      return response.data;
    } catch (error: any) {
      console.error('Error:', error.message);
      throw error; 
    }
  };

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [response, setResponse] = useState<TUser | undefined>();

  useEffect(() => {
    setTimeout(() => {
      setResponse(userData);
      setIsLoading(false);
    }, 500);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [username]);

  return { response, isLoading, getCheckUser, signupUser, allUserData };
};

export default useFakeUserAPI;
