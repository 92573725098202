import React, { SVGProps } from 'react';

const SvgLte2 = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg viewBox='0 0 24 24' className='svg-icon' {...props}>
			<g fill='none' fillRule='evenodd'>
				<path d='M0 0h24v24H0z' />
				<path
					d='M16.45 17.403l-1.272-1.543A4.987 4.987 0 0017 12a4.987 4.987 0 00-1.81-3.85l1.276-1.54A6.986 6.986 0 0119 12a6.987 6.987 0 01-2.55 5.403zm2.571 3.065l-1.277-1.539A8.978 8.978 0 0021 12a8.978 8.978 0 00-3.248-6.922l1.28-1.538A10.977 10.977 0 0123 12c0 3.315-1.477 6.392-3.979 8.468zM7.55 17.403A6.987 6.987 0 015 12c0-2.11.94-4.07 2.534-5.39L8.81 8.15A4.987 4.987 0 007 12c0 1.514.675 2.915 1.822 3.86l-1.273 1.543zm-2.57 3.065A10.977 10.977 0 011 12c0-3.31 1.473-6.383 3.969-8.46l1.279 1.538A8.978 8.978 0 003 12a8.978 8.978 0 003.256 6.93L4.98 20.467z'
					fill='currentColor'
					opacity={0.3}
				/>
				<path d='M11 14.292a2.5 2.5 0 112 0V20a1 1 0 01-2 0v-5.708z' fill='currentColor' />
			</g>
		</svg>
	);
};

export default SvgLte2;
