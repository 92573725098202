import React, { SVGProps } from 'react';

const SvgSnow = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg viewBox='0 0 24 24' className='svg-icon' {...props}>
			<g fill='none' fillRule='evenodd'>
				<path d='M0 0h24v24H0z' />
				<path
					d='M11 10.417V4a1 1 0 012 0v6.317l5.385-3.109a1 1 0 011 1.732l-5.47 3.16 5.385 3.108a1 1 0 01-1 1.732L13 13.88V20a1 1 0 01-2 0v-6.218L5.529 16.94a1 1 0 11-1-1.732L9.914 12.1 4.444 8.94a1 1 0 011-1.732L11 10.417z'
					fill='currentColor'
					opacity={0.3}
				/>
				<path
					d='M7.858 9.78a1 1 0 01-.666 1.348l-1.931.517a1 1 0 01-.518-1.932l1.932-.517a1.01 1.01 0 01.128-.026 1.01 1.01 0 01-.042-.123l-.518-1.932a1 1 0 111.932-.517l.518 1.931a1 1 0 01-.835 1.25zm9.168 5.293c.016.04.03.08.041.123l.518 1.931a1 1 0 01-1.932.518l-.517-1.932a1 1 0 01.834-1.25 1 1 0 01.666-1.348l1.931-.517a1 1 0 11.518 1.931l-1.932.518a1.01 1.01 0 01-.127.026zM15.97 9.78a1 1 0 01-.834-1.25l.517-1.932a1 1 0 011.932.517l-.518 1.932a1.01 1.01 0 01-.041.123c.042.006.085.014.127.026l1.932.517a1 1 0 01-.518 1.932l-1.931-.518a1 1 0 01-.666-1.347zm-9.167 5.293a1.01 1.01 0 01-.128-.026l-1.932-.518a1 1 0 11.518-1.931l1.932.517a1 1 0 01.665 1.348 1 1 0 01.834 1.25l-.517 1.932a1 1 0 11-1.932-.518l.518-1.931a1.01 1.01 0 01.042-.123zm5.111-7.635a1 1 0 01-1.5.098L9 6.12a1 1 0 111.414-1.414l1.414 1.414c.031.031.06.064.086.098A1.01 1.01 0 0112 6.12l1.414-1.414a1 1 0 111.414 1.414l-1.414 1.415a1 1 0 01-1.5-.098zm0 10.586a1.01 1.01 0 01-.086.097l-1.414 1.415A1 1 0 019 18.12l1.414-1.414a1 1 0 011.5.098 1 1 0 011.5-.098l1.414 1.414a1 1 0 01-1.414 1.415L12 18.12a1.01 1.01 0 01-.086-.097z'
					fill='currentColor'
					opacity={0.3}
				/>
			</g>
		</svg>
	);
};

export default SvgSnow;
