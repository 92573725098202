import React from 'react';
import { useNavigate } from 'react-router-dom';
import Aside, { AsideBody, AsideFooter, AsideHead } from '../../../components/layouts/Aside/Aside';
import LogoAndAsideTogglePart from './_parts/LogoAndAsideToggle.part';
import DarkModeSwitcherPart from './_parts/DarkModeSwitcher.part';
import { habitatPages } from '../../../config/pages.config';
import Nav, {
	NavButton,
	NavCollapse,
	NavItem,
	NavSeparator,
	NavTitle,
	NavUser,
} from '../../../components/layouts/Navigation/Nav';
import Badge from '../../../components/ui/Badge';
import UserTemplate from '../User/User.template';
import usersDb from '../../../mocks/db/users.db';
import Button from '../../../components/ui/Button';

const DefaultAsideTemplate = () => {
	const navigate = useNavigate();
	const logout = () => {
		localStorage.clear();

		navigate('/login');
	};
	return (
		<Aside>
			<AsideHead>
				<LogoAndAsideTogglePart />
			</AsideHead>
			<AsideBody>
				<Nav>
					<NavItem {...habitatPages.DashboardPage} />
					<NavItem {...habitatPages.CreateGoalPage} />
					<NavItem {...habitatPages.GoalListPage} />
					<NavItem  {...habitatPages.TrendPage}/>
				</Nav>
			</AsideBody>
			<AsideFooter>
				<Button
					icon='HeroArrowRightOnRectangle'
					variant='solid'
					color={'blue'}
					className='mb-10 ml-10'
					onClick={logout}>
					LOGOUT
				</Button>
				{/* <UserTemplate /> */}
				<DarkModeSwitcherPart />
			</AsideFooter>
		</Aside>
	);
};

export default DefaultAsideTemplate;
