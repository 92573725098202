// import React from 'react';
// import { useFormik } from 'formik';
// import classNames from 'classnames';
// import PageWrapper from '../components/layouts/PageWrapper/PageWrapper';
// import Button from '../components/ui/Button';
// import Input from '../components/form/Input';
// import LogoTemplate from '../templates/layouts/Logo/Logo.template';
// import FieldWrap from '../components/form/FieldWrap';
// import Icon from '../components/icon/Icon';
// import Validation from '../components/form/Validation';
// import { useNavigate } from 'react-router-dom';

// type TValues = {
//     email: string;
// };

// const ForgetPasswordPage = () => {
//     const navigate = useNavigate();

//     const formik = useFormik({
//         initialValues: {
//             email: '',
//         },
//         validate: (values) => {
//             const errors: Partial<TValues> = {};
//             if (!values.email) {
//                 errors.email = 'Required';
//             } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
//                 errors.email = 'Invalid email address';
//             }
//             return errors;
//         },
//         onSubmit: async (values: TValues, { setFieldError }) => {
//             try {
                
//             } catch (error) {
//                 setFieldError('email', 'We cannot find your email');
//             }
//         },
//     });

//     return (
//         <PageWrapper isProtectedRoute={false} className='bg-white dark:bg-inherit' name='Forgot Password'>
//             <div className='container mx-auto flex h-full items-center justify-center'>
//                 <div className='flex max-w-sm flex-col gap-8 border border-gray-300 rounded-lg p-6 bg-white shadow-lg dark:border-gray-700 dark:bg-gray-800'>
//                     <div>
//                         <LogoTemplate className='h-12' />
//                     </div>
//                     <div>
// 						<span className='text-2xl font-semibold'>Forget Password</span>
// 					</div>
// 					<div className='border border-zinc-500/25 dark:border-zinc-500/50' />
//                     <div>
//                         <span className='text-lg font-medium'>
//                             Enter your email and we'll send you a link to reset your password
//                         </span>
//                     </div>
//                     <form className='flex flex-col gap-4' onSubmit={formik.handleSubmit} noValidate>
//                         <div className={classNames({ 'mb-2': !formik.isValid })}>
//                             <Validation
//                                 isValid={formik.isValid}
//                                 isTouched={formik.touched.email}
//                                 invalidFeedback={formik.errors.email}
//                                 validFeedback='Good'>
//                                 <FieldWrap firstSuffix={<Icon icon='HeroEnvelope' className='mx-2' />}>
//                                     <Input
//                                         dimension='lg'
//                                         type='email'
//                                         autoComplete='email'
//                                         id='email'
//                                         name='email'
//                                         placeholder='Enter your email'
//                                         value={formik.values.email}
//                                         onChange={formik.handleChange}
//                                         onBlur={formik.handleBlur}
//                                     />
//                                 </FieldWrap>
//                             </Validation>
//                         </div>
//                         <div>
//                             <Button
//                                 size='lg'
//                                 variant='solid'
//                                 className='w-full font-semibold'
//                             >
//                                 Submit
//                             </Button>
//                         </div>
//                         <div className='flex justify-center items-center gap-2'>
//                             <Icon icon='HeroArrowLeft' className='text-blue-500' />
//                             <a 
//                                 className='cursor-pointer text-blue-500'
//                                 onClick={() => navigate('/login')}
//                             >
//                                 Back to Login
//                             </a>
//                         </div>
//                     </form>
//                 </div>
//             </div>
//         </PageWrapper>
//     );
// };

// export default ForgetPasswordPage;



import React, { useState } from 'react';
import { useFormik } from 'formik';
import classNames from 'classnames';
import PageWrapper from '../components/layouts/PageWrapper/PageWrapper';
import Button from '../components/ui/Button';
import Input from '../components/form/Input';
import LogoTemplate from '../templates/layouts/Logo/Logo.template';
import FieldWrap from '../components/form/FieldWrap';
import Icon from '../components/icon/Icon';
import Validation from '../components/form/Validation';
import { useNavigate } from 'react-router-dom';

type TValues = {
    email: string;
    newPassword?: string;
    confirmPassword?: string;
};

const ForgetPasswordPage = () => {
    const [step, setStep] = useState<'request' | 'reset'>('request');
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {
            email: '',
            newPassword: '',
            confirmPassword: '',
        },
        validate: (values) => {
            const errors: Partial<TValues> = {};
            if (step === 'request') {
                if (!values.email) {
                    errors.email = 'Required';
                } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
                    errors.email = 'Invalid email address';
                }
            } else if (step === 'reset') {
                if (!values.newPassword) {
                    errors.newPassword = 'Required';
                } else if (values.newPassword !== values.confirmPassword) {
                    errors.confirmPassword = 'Passwords must match';
                }
            }
            return errors;
        },
        onSubmit: async (values: TValues) => {
            try {
                setIsSubmitting(true);
                if (step === 'request') {
                    // Simulate an API call to request password reset link
                    await new Promise((resolve) => setTimeout(resolve, 1000)); // Simulate delay
                    
                    // Switch to reset password step
                    setStep('reset');
                } else if (step === 'reset') {
                    // Simulate an API call to reset password
                    await new Promise((resolve) => setTimeout(resolve, 1000)); // Simulate delay
                    
                    navigate('/login');
                }
            } catch (error) {
                // Handle error appropriately here
            } finally {
                setIsSubmitting(false);
            }
        },
    });

    // Handles click events for submission
    const handleClick = () => {
        formik.handleSubmit();
    };

    return (
        <PageWrapper isProtectedRoute={false} className='bg-white dark:bg-inherit' name='Forgot Password'>
            <div className='container mx-auto flex h-full items-center justify-center'>
                <div className='flex max-w-sm flex-col gap-8 border border-gray-300 rounded-lg p-6 bg-white shadow-lg dark:border-gray-700 dark:bg-gray-800'>
                    <div>
                        <LogoTemplate className='h-12' />
                    </div>
                    <div>
                        <span className='text-2xl font-semibold'>{step === 'request' ? 'Forget Password' : 'Reset Password'}</span>
                    </div>
                    <div className='border border-zinc-500/25 dark:border-zinc-500/50' />
                    <div>
                        <span className='text-lg font-medium'>
                            {step === 'request'
                                ? 'Enter your email and we\'ll send you a link to reset your password'
                                : 'Enter your new password below'}
                        </span>
                    </div>
                    <form className='flex flex-col gap-4' noValidate>
                        {step === 'request' && (
                            <div className={classNames({ 'mb-2': !formik.isValid })}>
                                <Validation
                                    isValid={formik.isValid}
                                    isTouched={formik.touched.email}
                                    invalidFeedback={formik.errors.email}
                                    validFeedback='Good'>
                                    <FieldWrap firstSuffix={<Icon icon='HeroEnvelope' className='mx-2' />}>
                                        <Input
                                            dimension='lg'
                                            type='email'
                                            autoComplete='email'
                                            id='email'
                                            name='email'
                                            placeholder='Enter your email'
                                            value={formik.values.email}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                        />
                                    </FieldWrap>
                                </Validation>
                            </div>
                        )}
                        {step === 'reset' && (
                            <>
                                <div className={classNames({ 'mb-2': !formik.isValid })}>
                                    <Validation
                                        isValid={formik.isValid}
                                        isTouched={formik.touched.newPassword}
                                        invalidFeedback={formik.errors.newPassword}
                                        validFeedback='Good'>
                                        <FieldWrap>
                                            <Input
                                                dimension='lg'
                                                type='password'
                                                id='newPassword'
                                                name='newPassword'
                                                placeholder='New password'
                                                value={formik.values.newPassword}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                        </FieldWrap>
                                    </Validation>
                                </div>
                                <div className={classNames({ 'mb-2': !formik.isValid })}>
                                    <Validation
                                        isValid={formik.isValid}
                                        isTouched={formik.touched.confirmPassword}
                                        invalidFeedback={formik.errors.confirmPassword}
                                        validFeedback='Good'>
                                        <FieldWrap>
                                            <Input
                                                dimension='lg'
                                                type='password'
                                                id='confirmPassword'
                                                name='confirmPassword'
                                                placeholder='Confirm password'
                                                value={formik.values.confirmPassword}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                        </FieldWrap>
                                    </Validation>
                                </div>
                            </>
                        )}
                        <div>
                            <Button
                                size='lg'
                                variant='solid'
                                className='w-full font-semibold'
                                onClick={handleClick} 
                                isLoading={isSubmitting}
                            >
                                {step === 'request' ? 'Send Reset Link' : 'Reset Password'}
                            </Button>
                        </div>
                        <div className='flex justify-center items-center gap-2'>
                            <Icon icon='HeroArrowLeft' className='text-blue-500' />
                            <a 
                                className='cursor-pointer text-blue-500'
                                onClick={() => navigate('/login')}
                            >
                                Back to Login
                            </a>
                        </div>
                    </form>
                </div>
            </div>
        </PageWrapper>
    );
};

export default ForgetPasswordPage;