// export const API_URL: string = 'https://mistral.convaiinnovations.com/api';
export const API_URL: any = process.env.REACT_APP_API_URL; //'http://localhost:5000/api';
export const Dummy_Auth: any = process.env.REACT_APP_MY_ENVIRONMENT_VARIABLE?.replace(/^"|"$/g, '');
export const Header: any = {
	headers: {
		'Content-Type': 'application/json',
		'accept': 'application/json',
		Authorization: `Bearer ${Dummy_Auth}`,
	},
};
export const Data: any = () => {
	const API_ID: any = localStorage.getItem('loggedin');
	return API_ID;
};
