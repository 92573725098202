import React, { SVGProps } from 'react';

const SvgCelsius = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg viewBox='0 0 24 24' className='svg-icon' {...props}>
			<g fill='none' fillRule='evenodd'>
				<path d='M0 0h24v24H0z' />
				<path
					d='M19.802 9.244a2.904 2.904 0 00-1.314-.999 4.353 4.353 0 00-1.584-.315c-.66 0-1.26.12-1.8.36s-1.005.57-1.395.99c-.39.42-.69.912-.9 1.476a5.221 5.221 0 00-.315 1.836c0 .696.102 1.332.306 1.908.204.576.495 1.071.873 1.485.378.414.831.735 1.359.963.528.228 1.122.342 1.782.342.684 0 1.29-.135 1.818-.405s.954-.627 1.278-1.071l1.818 1.278a5.825 5.825 0 01-2.07 1.647c-.816.39-1.77.585-2.862.585-.996 0-1.911-.165-2.745-.495a6.324 6.324 0 01-2.151-1.386 6.31 6.31 0 01-1.404-2.124c-.336-.822-.504-1.731-.504-2.727 0-1.02.177-1.941.531-2.763a6.153 6.153 0 011.458-2.097A6.473 6.473 0 0114.168 6.4a7.798 7.798 0 014.014-.351c.444.078.87.198 1.278.36.408.162.792.363 1.152.603.36.24.666.528.918.864l-1.728 1.368z'
					fill='currentColor'
				/>
				<path
					d='M2.72 8.92c0-.396.075-.768.225-1.116a2.92 2.92 0 011.539-1.539c.348-.15.72-.225 1.116-.225.396 0 .768.075 1.116.225a2.92 2.92 0 011.539 1.539c.15.348.225.72.225 1.116 0 .396-.075.768-.225 1.116a2.92 2.92 0 01-1.539 1.539c-.348.15-.72.225-1.116.225-.396 0-.768-.075-1.116-.225a2.92 2.92 0 01-1.539-1.539A2.789 2.789 0 012.72 8.92zm1.404 0c0 .408.144.756.432 1.044.288.288.636.432 1.044.432.408 0 .756-.144 1.044-.432.288-.288.432-.636.432-1.044 0-.408-.144-.756-.432-1.044A1.422 1.422 0 005.6 7.444c-.408 0-.756.144-1.044.432a1.422 1.422 0 00-.432 1.044z'
					fill='currentColor'
					opacity={0.3}
				/>
			</g>
		</svg>
	);
};

export default SvgCelsius;
